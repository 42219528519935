




import Vue from 'vue';

import { logoutWithAzure, setAzureToken } from '@/azureAd';

export default Vue.extend({
  name: 'manage',

  async mounted() {
    const ok = await setAzureToken();

    if (!ok) {
      logoutWithAzure();
      this.$router.push('/access-denied');
      return;
    }

    const redirect = sessionStorage.getItem('nextPath') || '/';
    sessionStorage.removeItem('nextPath');
    this.$router.push(redirect);
  },
});
